<template>
  <div>
    <!-- Product detail information -->
    <base-loader :isLoading="loading"></base-loader>
    <v-container class="mh-100">
      <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
      <v-row v-if="product">
        <v-col cols="9">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-img :src="product.featurePicture"></v-img>
                </v-col>
                <v-col cols="8">
                  <h1>{{ product.title }}</h1>
                  <v-chip class="mr-2 mt-2" dark small ripple v-for="(tag, i) in product.tags" :key="i">{{ tag.name }}</v-chip>
                  <h4 class="mt-2" v-if="product.completionTime">Time taken to complete document: {{ product.completionTime }}</h4>
                  <!-- Product details -->
                  <p class="mt-4">{{ product.content }}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card elevation="2">
            <v-card-text>
              <!-- add to cart section -->

              <h1>{{ product.price | currency("R", 2, { thousandsSeparator: ",", decimalSeparator: "." }) }}</h1>
              <h4 class="mb-0">VAT included</h4>

              <v-btn small class="mt-1 btn__primary-colour float-right" @click="addProductToCart()">Add to cart</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="product.disclaimer">
        <v-col>
          <h4>DIY Document Specific Disclaimer <span style="color:red">*</span></h4>
          <h5>{{ product.disclaimer }}</h5>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog width="750" v-model="showLoginState" @click:outside="showLoginState = false">
      <login-register @addToCart="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import LoginRegister from "@/components/Frontend/LoginRegister";
export default {
  components: {
    LoginRegister,
  },
  data() {
    return {
      showLoginState: false,
      loading: false,
      crumbs: [
        {
          text: "DIY Documents",
          disabled: false,
          href: "/products",
        },
        {
          text: "DIY Document Details",
          disabled: true,
        },
      ],
    };
  },
  created() {
    this.$eventHub.$on("addProductAfterLogin", this.addProductAfterLogin);
  },
  beforeDestroy() {
    this.$eventHub.$off("addProductAfterLogin");
  },
  methods: {
    addProductToCart() {
      const self = this;
      this.$apollo.queries.me
        .refetch()
        .then(() => {
          if (self.me != null) {
            self.$eventHub.$emit("addProductToCart", self.product);
          } else {
            self.showLoginState = true;
          }
        })
        .catch((e) => {
          self.showLoginState = true;
        });
    },
    showLogin(obj) {
      if (obj.state) {
        this.productToAdd = obj.productId;
        this.showLoginState = obj.state;
      }
    },
    closeDialog() {
      this.showLoginState = false;
    },
    addProductAfterLogin() {
      const self = this;
      this.$eventHub.$emit("addProductToCartAfterLogin", self.product);
    },
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      fetchPolicy: "network-only",
    },
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            title
            content
            featurePicture
            completionTime
            category
            price
            tagIDs
            tags {
              id
              name
              type
            }
            digitalItem
            disclaimer
            createdBy
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mh-100 {
  min-height: 100vh;
}
</style>
